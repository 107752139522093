<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="() => {
      visible = false
      baseInfoDisplay = false
    }"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link
                href="#alert"
                :title="`当前告警 (${count.alert})`"
              />
              <a-anchor-link href="#monitor" title="监控" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>
              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="URL">
                    <a :href="detail.url" target="_blank">{{ detail.url }}</a>
                  </a-descriptions-item>
                  <a-descriptions-item label="协议类型">
                    <a-tag color="blue">
                      {{ detail.protocol.toUpperCase() }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属群组">
                    <a-tag v-for="group in detail.groups" :key="group.id">
                      {{ group.name }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag
                      :status="detail.status"
                    ></source-status-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="监控频率 (秒)">
                    {{ detail.delay }}
                  </a-descriptions-item>
                  <a-descriptions-item label="请求超时 (秒)">
                    {{ detail.timeout }}
                  </a-descriptions-item>

                  <a-descriptions-item label="重试次数" v-if="baseInfoDisplay">
                    {{ detail.retries }}
                  </a-descriptions-item>
                  <a-descriptions-item label="响应内容" v-if="baseInfoDisplay">
                    {{ detail.required }}
                  </a-descriptions-item>
                </a-descriptions>
                <div style="text-align: center; font-size: 12px; color: #096DD9; margin-bottom: 16px;">
                  <a @click="baseInfoDisplay = !baseInfoDisplay">
                    <a-icon theme="filled" :type="baseInfoDisplay ? 'caret-up': 'caret-down'"></a-icon>
                  </a>
                </div>
              </div>
            </div>
            <div id="alert">
              <div class="small-module-title">
                <a-icon type="alert"></a-icon>
                <span> 当前告警 ({{ count.alert }})</span>
              </div>

              <div style="padding: 0 16px">
                <div style="height: 24px; margin-bottom: 24px">
                  <severity-radio-group
                    v-model="currentSeverity"
                    style="float: right"
                  ></severity-radio-group>
                </div>

                <div style="max-height: 400px; overflow: scroll">
                  <alert-timeline
                    :severity="currentSeverity"
                    :source-id="detail.id"
                    source-type="site"
                    @total="v => (count.alert = v)"
                    style="margin-top: 8px"
                  ></alert-timeline>
                </div>
              </div>
            </div>
            <div id="monitor">
              <div class="small-module-title">
                <a-icon type="line-chart"></a-icon>
                <span> 监控</span>
              </div>
              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>
                <a-row :gutter="16">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :sm="24"
                    :lg="12"
                    style="margin-bottom: 16px"
                  >
                    <monitor-chart-card
                      :datetime-range="datetimeRange"
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <!-- <a-tab-pane key="monitor" tab="监控指标">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane> -->
      <a-tab-pane key="rule" tab="告警规则">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import { getSite, getSiteMonitorHistory, getSiteMonitorItemList, getSiteMonitorTriggerList, updateSiteMonitorTrigger } from '@/api/site'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
// import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'

export default {
  name: 'SiteDrawer',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    DatetimeRange,
    MonitorChartCard,
    SeverityRadioGroup,
    SourceStatusTag,
    // MonitorItemTable,
    MonitorTriggerTable
  },
  data () {
    return {
      visible: false,
      baseInfoDisplay: false,
      currentSeverity: 'all',
      detail: {
        id: '',
        name: '',
        url: '',
        protocol: '',
        delay: '',
        retries: '',
        timeout: '',
        required: '',
        created_at: '',
        updated_at: ''
      },
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      historyFunc: getSiteMonitorHistory,
      getItemListFunc: getSiteMonitorItemList,
      getTriggerFunc: getSiteMonitorTriggerList,
      updateFunc: updateSiteMonitorTrigger,
      count: {
        alert: 0
      }
    }
  },
  computed: {
    monitorItems () {
      return [
        {
          key: `web.test.in[${this.detail.name},1,bps]`,
          title: '下载速度 (bps)'
        },
        {
          key: `web.test.rspcode[${this.detail.name},1]`,
          title: '状态码'
        },
        {
          key: `web.test.time[${this.detail.name},1,resp]`,
          title: '响应时间 (ms)'
        }
      ]
    }
  },
  methods: {
    show (id) {
      getSite(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
    }
  }
}
</script>
