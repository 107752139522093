<template>
  <div>
    <list-layout
      :table-operation-width="0"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :get-table-data-func="getFunc"
      :fetch-id="sourceId"
      :tools-list="['search', 'leftTool', 'reload']"
      @tableSelectData="data => tableSelectData = data"
      :default-selected="tableDefaultSelect"
      :refresh="refresh"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <a-space>
          <a-button
            v-show="tableSelectData.length"
            type="primary"
            @click="change(true)"
          >
            批量启用
          </a-button>
          <a-button
            v-show="tableSelectData.length"
            type="danger"
            @click="change(false)"
          >
            批量停用
          </a-button>
        </a-space>
      </template>
      <template #severity="{ slotProps }">
        <alert-severity-tag :severity="slotProps.text"></alert-severity-tag>
      </template>
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          {{ slotProps.text }}
        </a-tooltip>
      </template>
      <template #enabled="{ slotProps }">
        <a-switch
          :checked="slotProps.text"
          @click="checked => click(slotProps.record.id, checked)"
        ></a-switch>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from '@/components/ListLayout.vue'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag.vue'

export default {
  name: 'MonitorTriggerTable',
  components: {
    ListLayout,
    AlertSeverityTag
  },
  props: {
    getFunc: {
      type: Function,
      required: true
    },
    updateFunc: {
      type: Function,
      required: true
    },
    sourceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      columnsAll: [
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 100,
          scopedSlots: {
            customRender: 'severity'
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'enabled',
          title: '启用状态',
          width: 160,
          scopedSlots: {
            customRender: 'enabled'
          }
        }
      ],
      columnDefaultSelected: ['severity', 'name', 'enabled'],
      tableSelectData: [],
      refresh: false,
      tableDefaultSelect: []
    }
  },
  methods: {
    click (id, checked) {
      this.updateFunc(this.sourceId, { id, enabled: checked }).then(res => {
        this.$message.success(res.message)
        this.refresh = !this.refresh
      })
    },
    async change (enabled) {
      for (let i = 0; i < this.tableSelectData.length; i++) {
        await this.updateFunc(this.sourceId, { id: this.tableSelectData[i].key, enabled })
      }
      this.$message.success('批量操作成功')
      this.tableDefaultSelect = []
      this.refresh = !this.refresh
    }
  }
}
</script>
